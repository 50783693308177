<!-- eslint-disable vue/no-v-html -->
<template>
	<v-expansion-panel-content lazy>
		<template v-slot:header>
			<div>
				<v-icon :color="value.selected ? 'success' : 'error'" class="mr-2">
					{{ value.selected ? 'check' : 'clear' }}
				</v-icon>
				<span class="mb-1" v-html="highlightedSearch"></span>
			</div>
		</template>
		<v-card>
			<v-card-text>
				<v-layout column>
					<w-switch v-model="resource.selected" :label="$t('application.authorize_set_up')" />
					<w-form-builder
						v-if="resource?.selected"
						v-model="resource.params"
						:fields="formParams"
						:readonly="readonly"
						:validate-on-blur="false"
						:validation.sync="isFormValid"
						@submit="setUpResource(resource)"
					/>
					<v-flex text-xs-right>
						<w-btn-save :disabled="!isValid || !isDirty || readonly" :loading="value.loading" @click="setUpResource(resource)" />
					</v-flex>
				</v-layout>
			</v-card-text>
		</v-card>
		<template v-slot:actions>
			<v-icon>expand_more</v-icon>
		</template>
	</v-expansion-panel-content>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ApplicationVendorPanel',
	mixins: [ApplicationsModuleGuard],
	props: {
		fields: {
			required: true,
			type: Array
		},
		magicMailModelsRegEx: {
			default: null,
			required: false,
			type: RegExp
		},
		value: {
			required: true,
			type: Object
		},
		title: {
			required: true,
			type: String
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		search: {
			default: '',
			required: false,
			type: String
		},
	},
	data: function () {
		return {
			isFormValid: false,
			loading: false,
			resource: {}
		}
	},
	computed: {
		applicationVendorId: function () {
			return this.value?.application_vendor_id
		},
		formParams: function () {
			const result = []
			if (Array.isArray(this.fields)) {
				this.fields.forEach(field => {
					const formatedField = this.getFormatedField(field)
					if (formatedField) {
						result.push(formatedField)
					}
				})
			}
			return result
		},
		isDirty: function () {
			if (this.value.selected != this.resource?.selected) {
				return true
			}

			if (!this.resource?.selected) {
				return false
			}

			const oldParams = this.value.params
			const newParams = this.resource?.params ?? {}

			const entries = Object.entries(newParams)

			for (const [key, value] of entries) {
				if (value != oldParams[key]) {
					return true
				}
			}
			return false
		},
		isValid: function () {
			return !this.resource?.selected || this.isFormValid
		},
		lowerCaseSearch: function () {
			return this.search?.toLowerCase()
		},
		lowerCompanyName: function () {
			return this.value.company?.toLowerCase()
		},
		lowerCaseClientCode: function () {
			return this.value.client_code?.toLowerCase()
		},
		highlightedSearch: function () {
			if (!this.search) {
				return this.title
			}

			if (this.lowerCompanyName.includes(this.lowerCaseSearch)) {
				return this.$highlightMatching(this.value.company, this.lowerCaseSearch);
			}

			if (this.value.siret?.includes(this.lowerCaseSearch) || this.lowerCaseClientCode?.includes(this.lowerCaseSearch)) {
				return this.$highlightMatching(this.value.company, this.value.company);
			}

			return this.value.company;
		}
	},
	watch: {
		value: {
			handler: 'copyValue'
		}
	},
	mounted: function () {
		this.copyValue(this.value)
	},
	methods: {
		copyValue: function (value) {
			this.resource = {
				...value
			}
		},
		getFormatedField: function (field) {
			let result = null
			if (!field.auto) {
				if (field.type == 'email' && this.magicMailModelsRegEx) {
					field.rules = [
						value => {
							return !value || !this.magicMailModelsRegEx.test(value) ? true : this.$t('application.rules.forbidden_email_address')
						}
					]
				}
				field.label = this.$te(field.label) ? this.$t(field.label) : field.label
				result = field
			} else if (this.applicationVendorId) {
				result = { ...field, readonly: true }
			}
			return result
		},
		setUpResource: function (resource) {
			if (!resource.selected) {
				this.$emit('unselect')
				return
			}
			if (this.isValid) {
				this.$emit('select', resource.params)
			}
		},
	}
}
</script>
